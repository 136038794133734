import { Cluster, Vector as VectorSource } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer, Heatmap } from "ol/layer";
import { Point } from 'ol/geom';
import { getCenter } from "ol/extent";
import Vector from "ol/layer/Vector"
import { Fill, Stroke, Text, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import Feature from 'ol/Feature.js';

import { getFeatureType } from "../../commons/geoSpatialFunctions"
import { isMobile } from 'react-device-detect';

const CLUSTER_DISTANCE = 160
const CLUSTER_MIN_DISTANCE = 145
const DEFAULT_STROKE_COLOR = "#000000";
const DEFAULT_STROKE_WIDTH = 0.8;
const DEFAULT_FILL_COLOR = '#3399CC';
const DEFAULT_FILL_OPACITY = 0.6;
const BREAKPOINT_ZOOM = 0;// isMobile ? 16.7 : 15.5; // mayor valor es más zoom
const BREAKPOINT_ZOOM_SOLAR = 16;// isMobile ? 16.7 : 15.5; // mayor valor es más zoom
const STROKE_MULTIPLY = 6;
const COLOR_SOLAR = "#bae80077";
const COLOR_BUILDINGS = "#CC333399";
var highlightedFeature = null;
//let key_energy = "solar_energy_total"
let keys_energy = ["SolEnergia", "solar_energy_total"]
export { BREAKPOINT_ZOOM }
function isCluster(feature) {
    return Array.isArray(feature.get('features'));
}
export function updateLayerStyle(map, vectorLayer, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature, breakpoint_zoom = BREAKPOINT_ZOOM, cluster_distance = CLUSTER_DISTANCE, cluster_min_distance = CLUSTER_MIN_DISTANCE) {
    let numberOfFeatures = 0;
    if (vectorLayer && vectorLayer != [] && vectorLayer != false && vectorLayer.length > 0 && map != null && map != undefined) {
        const zoom = map?.getView().getZoom() ?? 20;
        numberOfFeatures = getNumberOfVisibleFeatures(map);

        for(let v of [0]){
            const currentClusterSource = vectorLayer[v].getSource();
            //console.log("vectorLayer[v]", vectorLayer[v])
            if (zoom < BREAKPOINT_ZOOM_SOLAR && currentClusterSource instanceof Cluster) {
                // Asegurarse de que el source actual es un ClusterSource
                vectorLayer[v].setSource(createClusterSource(currentClusterSource.getSource(), cluster_distance, cluster_min_distance))
                vectorLayer[v]?.setStyle(feature => {
                    return styleFunction(feature, map, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature, numberOfFeatures, "Cluster")
            
                });
            } else if (currentClusterSource instanceof Cluster) {
                vectorLayer[v].setSource(createClusterSource(currentClusterSource.getSource(), 0, 0))
                
                // vectorLayer[v].setSource(createIndividualFeaturesSource( currentClusterSource.getFeatures()));
                // vectorLayer[v]?.setStyle(feature => {
                //     return styleFunction(feature, map, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature, numberOfFeatures, "ClusterIndividual")
            
                // });
            }else if(currentClusterSource instanceof VectorSource){
                vectorLayer[v]?.setStyle(feature => {
                         return styleFunction(feature, map, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature, numberOfFeatures, "ClusterIndividual")
                
                     });
            }
            // vectorLayer[v]?.setStyle(feature => {
            //     return styleFunction(feature, map, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature, numberOfFeatures)
        
            // });
        }
        

       
    }

   
}

function createIndividualFeaturesSource(features) {
    //console.log("createIndividualFeaturesSource", features)
    return new VectorSource({
        features: features.map(feature => {
            try {
                // Si deseas convertir cualquier geometría a un punto basado en su centro, descomenta las siguientes líneas
                 //const center = getCenter(feature.getGeometry().getExtent());
                 return new Feature(new Point(getCenter(feature.getGeometry().getExtent())));
                
                // De lo contrario, simplemente devuelve la feature tal cual
                return feature;
            } catch {
                console.error("Error on feature vector source", feature)
                return feature;
            }
        })
    });
}

// Función para crear la fuente del clúster
function createClusterSource(source, distance = 100, minDistance = 5) {
    return new Cluster({
        distance: distance,
        minDistance: minDistance,
        source: source,
        geometryFunction: feature => {
            try {
                return new Point(getCenter(feature.getGeometry().getExtent()))
            } catch {
                return feature.getGeometry();
            }
        }
    });
}
// Función principal para obtener la capa vectorial
export function getVectorLayerFromSources(vectorLayers, vectorSources, allPoints, strokeColor, strokeWidth, fillColor, fillOpacity, map, getBgColorFromFeature) {

    const source = new VectorSource({ features: allPoints });
    // for(let p of allPoints){
    //     console.log("Calculate min max", p)
    // }
    const clusterSource = createClusterSource(source);
    const individualSourcePoints = createIndividualFeaturesSource(allPoints);
    let numberOfFeatures = 0;
    if (map != null && map != undefined) {
        numberOfFeatures = getNumberOfVisibleFeatures(map);
    }
    let clusters ;
    const zoom = map?.getView().getZoom() ?? 20;
    //if (zoom < BREAKPOINT_ZOOM_SOLAR){
        clusters = new VectorLayer({
            source: clusterSource,
            style: (feature) => styleFunction(feature, map, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature, numberOfFeatures, "Cluster")
        });
    // }
    // else{
    //     clusters = new VectorLayer({
    //         source: source,
    //         style: (feature) => styleFunction(feature, map, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature, numberOfFeatures, "ClusterIndividual")
    //     });
    // }
   
    const individual = new VectorLayer({
        source: source,
        style: (feature) => styleFunction(feature, map, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature, numberOfFeatures, "Geometry")
    });
    // const heat = new Heatmap({
    //     source : clusterSource,
    //     blur:50,
    //     opacity:0.5,
    //     radius : 85,
    //     weight : (feature)=>{
    //         let w = 0
    //         const zoom = map?.getView().getZoom() ?? 20;
    //         if (feature && "values_" in feature &&
    //             "features" in feature.values_ &&
    //             feature.values_.features.length >= 1){
    //                 for(let f of feature.values_.features){
    //                     if("values_" in f && key_energy in f.values_){
    //                         w += f.values_[key_energy]
    //                     }
    //                 }
    //         }
    //         console.log("Heatmap",feature,  w)
              
    //         w = Math.sqrt(Math.sqrt(Math.sqrt(w)))/(10- (zoom/20)*5);
    //         console.log("Heatmap 2", w)

    //         return w;
    //     }
    // })
    //  const heat = new Heatmap({
    //     source : allPoints[0],
    //     blur:40,
    //     radius : 65,
    //     weight : (feature)=>{
    //         let w = 0
    //         const zoom = map?.getView().getZoom() ?? 20;
    //         if (feature && "values_" in feature &&
    //             "features" in feature.values_ &&
    //             feature.values_.features.length >= 1){
    //                 for(let f of feature.values_.features){
    //                     if("values_" in f && key_energy in f.values_){
    //                         w += f.values_[key_energy]
    //                     }
    //                 }
    //         }
    //         console.log("Heatmap",feature,  w)
              
    //         w = Math.sqrt(Math.sqrt(Math.sqrt(w)))/(10- (zoom/20)*5);
    //         console.log("Heatmap 2", w)

    //         return w;
    //     }
    // })
    
    return [   clusters, individual];
}

function getNumberOfVisibleFeatures(map) {
    let count = 0;
    const extent = map.getView().calculateExtent(map.getSize());
    if (map != undefined && map.getLayers() != undefined) {
        const layers = map.getLayers().getArray();
        if (layers.length > 1) {
            const vectorLayer = layers[1]; // Suponiendo que tu VectorLayer está en esta posición
            if (vectorLayer instanceof Vector) {
                const vectorSource = vectorLayer.getSource();
                if (vectorSource.getFeatures().length > 0) {
                    // Tu lógica aquí
                    vectorSource.forEachFeature((feature) => {
                        if (feature.getGeometry().intersectsExtent(extent)) {
                            count++;
                        }
                    });
                }
            }
        }
        if (layers.length > 2) {
            const vectorLayer = layers[2]; // Suponiendo que tu VectorLayer está en esta posición
            if (vectorLayer instanceof Vector) {
                const vectorSource = vectorLayer.getSource();
                if (vectorSource.getFeatures().length > 0) {
                    // Tu lógica aquí
                    vectorSource.forEachFeature((feature) => {
                        if (feature.getGeometry().intersectsExtent(extent)) {
                            count++;
                        }
                    });
                }
            }
        }
    }
    return count;
}

// Función de estilo
function styleFunction(feature, map, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature, numberOfFeatures, forceStyle = false) {
    /*
    forceStyle : false para no forzarlo. Cluster o Geometry para forzar un estilo
    */

    //console.log("feature", feature)


    const zoom = map?.getView().getZoom() ?? 20;
    let breakpointZoomOut = zoom <= BREAKPOINT_ZOOM;
    let breakpointNumberOfFeatures = numberOfFeatures <= 1500
    const geometryType = getFeatureType(feature);
    // console.log("Breakpoint", geometryType, breakpointZoomOut, feature)

    if (!breakpointZoomOut && geometryType != "Point" && (forceStyle == false || forceStyle == "Geometry")) {
        
        return individualStyleFromVectorLayer(strokeColor, strokeWidth, fillColor, fillOpacity, feature, getBgColorFromFeature);
    // } else if(forceStyle == "HeatMap"){
    //     return heatmapStyle(strokeColor, strokeWidth, fillColor, fillOpacity, feature, getBgColorFromFeature)
    }  else if(forceStyle == "ClusterIndividual"){
        return individualStylePoint(strokeColor, strokeWidth, fillColor, fillOpacity, feature, getBgColorFromFeature);
    } else {
        const features = feature.get('features');
        const size = features.length;
        return clusterSolarStyle(feature, size, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature, zoom);
    }
}

// function heatmapStyle(strokeColor, strokeWidth, fillColor, fillOpacity, feature, getBgColorFromFeature){
//     var heatmap = new ol.layer.Heatmap({ 

//     })

// }



export function individualStylePoint(strokeColor, strokeWidth, fillColor, fillOpacity, feature, getBgColorFromFeature, isHigtlighted = false) {
    //console.log("Aquí")
    
    try {
        if (!feature) {
            return
        }
        const bgColor = COLOR_SOLAR;
        const r = Math.max(10, Math.min(get_solar_energy_total_from_feature(feature), 50));
         console.log("r", r);
        //if(feature.getGeometry())
         // Crea el estilo del punto
        const style = new Style({
            image: new CircleStyle({
                radius: r, // Ajusta esto según cómo quieras que el radio dependa de la propiedad
                stroke: new Stroke({
                    color: strokeColor,
                    width: strokeWidth
                }),
                fill: new Fill({
                    color: bgColor
                })
            })
        });

    return style;
        // var features = feature.get('features');
        // //console.log("Features", features)
        // if (features && features.length && features.length > 1) {
        //     //console.log("MAS DE UNA FEATURE", features)
        //     let result = []
        //     for (let element of features) {
        //         let styleCopy = style.clone();
        //         styleCopy.setGeometry(element.getGeometry());
        //         result.push(styleCopy)
        //     }
        //     //console.log("Result", result)
        //     return result;
        // }
        // style.setGeometry(features[0].getGeometry());
        // return style;
    } catch (e) {
        console.error("Problema con los estilos", e)
        return
    }

}
export function individualStyleFromVectorLayer(strokeColor, strokeWidth, fillColor, fillOpacity, feature, getBgColorFromFeature, isHigtlighted = false) {
    //console.log("Aquí")
    
    try {
        if (!feature) {
            return
        }
        const bgColor = getBgColorFromFeature(feature);
        // console.log("getBgColorFromFeature", bgColor, `rgba(${bgColor}, ${fillOpacity || DEFAULT_FILL_OPACITY})`);
        let backgroundColor = bgColor ? `rgba(${bgColor}, ${fillOpacity || DEFAULT_FILL_OPACITY})` : fillColor ? `rgba(${fillColor}, ${fillOpacity || DEFAULT_FILL_OPACITY})` : DEFAULT_FILL_COLOR;
        // console.log("backgroundColor2", backgroundColor);
        //if(feature.getGeometry())
       // console.log("feature", feature)

        let style = new Style({
            stroke: new Stroke({
                color: strokeColor || DEFAULT_STROKE_COLOR,
                width: isHigtlighted ? (strokeWidth * STROKE_MULTIPLY || DEFAULT_STROKE_WIDTH * STROKE_MULTIPLY) : strokeWidth || DEFAULT_STROKE_WIDTH
            }),
            fill: new Fill({
                color: COLOR_BUILDINGS
            })
        });
   
        style.setGeometry(feature.getGeometry());
        return style;
    } catch (e) {
        console.error("Problema con los estilos - individualStyle", e)
        return
    }

}
// Estilo para geometrías (zoom < 10)
export function individualStyleFromCluster(strokeColor, strokeWidth, fillColor, fillOpacity, feature, getBgColorFromFeature, isHigtlighted = false) {
    //console.log("Aquí")
    
    try {
        if (!feature) {
            return
        }
        const bgColor = getBgColorFromFeature(feature);
        // console.log("getBgColorFromFeature", bgColor, `rgba(${bgColor}, ${fillOpacity || DEFAULT_FILL_OPACITY})`);
        let backgroundColor = bgColor ? `rgba(${bgColor}, ${fillOpacity || DEFAULT_FILL_OPACITY})` : fillColor ? `rgba(${fillColor}, ${fillOpacity || DEFAULT_FILL_OPACITY})` : DEFAULT_FILL_COLOR;
        // console.log("backgroundColor2", backgroundColor);
        //if(feature.getGeometry())
      //  console.log("feature", feature)

        let style = new Style({
            stroke: new Stroke({
                color: strokeColor || DEFAULT_STROKE_COLOR,
                width: isHigtlighted ? (strokeWidth * STROKE_MULTIPLY || DEFAULT_STROKE_WIDTH * STROKE_MULTIPLY) : strokeWidth || DEFAULT_STROKE_WIDTH
            }),
            fill: new Fill({
                color: COLOR_BUILDINGS
            })
        });
        var features = feature.get('features');
        if (features && features.length && features.length > 1) {
            //console.log("MAS DE UNA FEATURE", features)
            let result = []
            for (let element of features) {
                let styleCopy = style.clone();
                styleCopy.setGeometry(element.getGeometry());
                result.push(styleCopy)
            }
            //console.log("Result", result)
            return result;
        }
        style.setGeometry(features[0].getGeometry());
        return style;
    } catch (e) {
        console.error("Problema con los estilos - individualStyle", e)
        return
    }

}
export function handleMapPointerMove(map, e, layer, tooltip, tooltipRef, strokeColor, strokeHoverColor, strokeWidth, fillColor, fillPolygonsOpacity, fillPointsOpacity, getBgColorFromFeature = false, isGetCoordinatesOnClickEnabled = false) {
    const view = map.getView();
    const currentZoom = view.getZoom();
    if (currentZoom < layer.breakpoint_zoom || isGetCoordinatesOnClickEnabled) {
        return true;
    }
    const pixel = map.getEventPixel(e.originalEvent);
    const hit = map.hasFeatureAtPixel(pixel);
    map.getTargetElement().style.cursor = hit ? "pointer" : "";
    const featureClusters = map.forEachFeatureAtPixel(pixel, function (feature) {
        if (isCluster(feature)) {
            return feature; // Retorna solo si la feature es un clúster
        }
    });
    const featurePolygons = map.forEachFeatureAtPixel(pixel, function (feature) {
        if (!isCluster(feature)) {
            return feature; // Retorna solo si la feature es un clúster
        }
    });
    const geometryType = getFeatureType(featureClusters);

        // if (highlightedFeature) {
        //     const geometryType = getFeatureType(highlightedFeature);
        //     let style;
        //     if (geometryType != "Point") {
        //         style = layer.createGeometryStyle(strokeColor, strokeWidth, fillColor, fillPolygonsOpacity, highlightedFeature, getBgColorFromFeature, false);
        //     } else {
        //         //const features =  highlightedFeature.get('features');
        //         //const size = features.length;
        //         //style = createClusterStyle(feature, size, strokeColor, strokeWidth, fillColor, fillPointsOpacity, getBgColorFromFeature);

        //     }
        //     highlightedFeature.setStyle(style);
        //     highlightedFeature = null;
        // }
        
        //  if (getBgColorFromFeature != false) {
        //      const geometryType = getFeatureType(featurePolygons);
        //      if (geometryType != "Point" && geometryType != "Features") {
        //          const highlightStyle = layer.createGeometryStyle(strokeHoverColor, strokeWidth, fillColor, fillPolygonsOpacity, featurePolygons, getBgColorFromFeature, true);
        //          if (featurePolygons) {
        //             featurePolygons.setStyle(highlightStyle);
        //              highlightedFeature = featurePolygons;
        //          }
        //      }

        //  }


    // Tooltip info disabled
    const TOOLTIP_DEFAULT_TEXT = "No hay datos para esta geometría";
    const tooltipIsEnabled = true;
    if (featureClusters && getFeatureType(featureClusters) == "Features" &&  tooltipIsEnabled) {
        let type = getFeatureType(featureClusters);
        //if(type == "Point"){
        let tooltipText = featureClusters.get("tooltip");
        if (layer.getTooltip != false) {
            tooltipText = layer.getTooltip(featureClusters);
        }
        // Recupera el tooltip asociado a la feature
        if (!tooltipText) {
            tooltipText = TOOLTIP_DEFAULT_TEXT;
        }
        tooltipRef.current.innerHTML = tooltipText;
        tooltip.setPosition(e.coordinate);
        // }

    } else {
        tooltipRef.current.innerHTML = "";
        tooltip.setPosition(undefined);
    }
}
export function get_solar_energy_total_from_feature(feature){
    if (
        "values_" in feature &&
        [...Object.keys(feature.values_)].some(key => keys_energy.includes(key) )
    ) {
        for(let key of keys_energy){
            if(key in feature.values_){
                return  feature.values_[key]
            }
        }
        

    }else if (feature && "values_" in feature &&
                "features" in feature.values_){
                    let energy = 0
                    for(let f of feature.values_.features){
                        if (
                            "values_" in f &&
                            [...Object.keys(f.values_)].some(key => keys_energy.includes(key) )
                        ) {
                            for(let key of keys_energy){
                                if(key in feature.values_){
                                    energy +=   f.values_[key]
                                }
                            }
                        }
                    }
                    return energy;
                
    
    }else{
        console.warn("get_solar_energy_total Error obteniendo la energía de la feature", feature)
        return null;
    }
}
// Estilo para clústeres (zoom >= 10)
function clusterSolarStyle(feature, size, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature, current_zoom) {
    let solar_potential = 0
    let solar_potential_absolute = 0
    let solar_potential_max = 0
    let solar_text = ""
    let solar_text_absolute = ""
    if(size > 1){
        const features = feature.get('features')
       // console.log(features)
        for (let f of features) {
            const this_solar_potential =  get_solar_energy_total_from_feature(f)
            solar_potential_absolute += this_solar_potential
            if(this_solar_potential > solar_potential_max){
                solar_potential_max = this_solar_potential
            }
            //console.log("Feature", f, "solar potential", solar_potential)

        }
        solar_potential = solar_potential_absolute / size
    }else{
        solar_potential = get_solar_energy_total_from_feature(feature)
        solar_potential_absolute = solar_potential
    }

    
    solar_text = new Intl.NumberFormat("es-ES", {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,

    }).format(solar_potential).toString() + " KWh";

    solar_text_absolute = solar_text + " de media\n" + new Intl.NumberFormat("es-ES", {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,

    }).format(solar_potential_absolute).toString() + " KWh en total";


    //console.log("Zoom values", current_zoom, solar_potential,Math.sqrt(Math.sqrt(solar_potential_absolute)) )
    return new Style({
        image: new CircleStyle({
           // radius:  Math.max(2, Math.min(100,Math.sqrt(solar_potential)/10))*Math.max(1,Math.sqrt(current_zoom*10)/10), // Math.max(2, Math.min(150,solar_potential/2000)),
            radius :(Math.sqrt(Math.sqrt(solar_potential_absolute))/2)*Math.max(1,Math.sqrt(current_zoom*10)/7),
           //radius : Math.sqrt(Math.sqrt(solar_potential_max)), //*Math.max(1,Math.sqrt(current_zoom*10)/7),
            stroke: new Stroke({
                color: strokeColor || DEFAULT_STROKE_COLOR,
                width: strokeWidth || DEFAULT_STROKE_WIDTH
            }),
            fill: new Fill({ color: COLOR_SOLAR })
        }),
        // text: solar_potential ? new Text({
        //    // text: solar_potential_absolute == solar_potential ? solar_text : solar_text_absolute,
        //    text = ""
        //     font: "16px Arial Bold",
            
        //     fill: new Fill({
        //         color: 'rgba(0, 0, 0, 1)'
        //     }),
        //     backgroundFill: new Fill({
        //         color: 'rgba(255, 255, 255, 0.7)' // Cambia el color y la opacidad según necesites
        //     }),
        //     backgroundStroke: new Stroke({
        //         color: 'rgba(0, 0, 0, 0.7)', // Opcional: color del borde del fondo, cambia según necesites
        //         width: 1 // Opcional: grosor del borde del fondo
        //     }),
        //     padding: [5, 5, 5, 5],
        //     radius:5
        // }):null
    });
}

export function getBgColorFromFeatureGeneric(allPolygons, index, getBgColorFromFeature) {
    let bgColor = false;
    let feature = allPolygons[index].values_
    bgColor = getBgColorFromFeature(feature)
    return bgColor;
}