
import LayerClass, { hexToRgb, FullModalInfo, ImageWithCheck } from "./LayerCommons";
import { getVectorLayerFromSources, get_solar_potential_from_feature, updateLayerStyle, clusterStyleFunction, handleMapPointerMove, BREAKPOINT_ZOOM } from "../Styles/StyleSolarMapV2" //individualStyleFromCluster
import TabSolar from "./TabSolar"
import { renderToString } from 'react-dom/server'

const legend = () => false;

const getBackground = (feature) => {
  let defaultColor = hexToRgb("#0000FF");
  return defaultColor;
};


const getTooltip = (feature) => {
  //console.log("feature", feature)
  let v = get_solar_potential_from_feature(feature);
  if (v != null) {
    let unit = " €"
    v = new Intl.NumberFormat("es-ES", {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,

    }).format(v).toString();
    return "Potencial solar : " + v + unit
  }
  if (
    feature &&
    "values_" in feature &&
    "reference" in feature["values_"]){
      return feature["values_"]["reference"]
    }else{
     // console.log("feature", feature)
    }
  return ""
}


export const getSolarModal = (feature) => {
 // console.log("getModalBody feature", feature)
 if("feature" in feature){
  feature = feature["feature"]
 }
  if (
    feature &&
    "values_" in feature 
  ) {
    const data = feature.values_;
    let data_processed = JSON.parse(JSON.stringify(data));
    const uri = "https://www1.sedecatastro.gob.es/CYCBienInmueble/OVCListaBienes.aspx?rc1=";
    let catastroUrl = "";
    let catastroImageUrl = "";
    if(Object.keys(data).includes("Link_catastro")){
      catastroUrl= data["Link_catastro"]
    }else if(Object.keys(data).includes("reference")){
      const rc1 = data["reference"].substring(0, 7);
      const rc2 = data["reference"].substring(7, 14);
      catastroUrl = uri + rc1 + "&rc2=" + rc2;

      data_processed["Link_catastro"] = catastroUrl;

    }
    if(Object.keys(data).includes("reference")){
      catastroImageUrl= "https://ovc.catastro.meh.es/OVCServWeb/OVCWcfLibres/OVCFotoFachada.svc/RecuperarFotoFachadaGet?ReferenciaCatastral="+data_processed["reference"];
      data_processed["Link_image"] = catastroImageUrl
    }
    let tabSolar = <TabSolar feature={feature}/>
    const result =  <div style={{ width: "100%", display: "flex", flexWrap: "wrap", gap: "1.5rem", paddingRight: "1rem" }}>


          {tabSolar}


      {/* <div style={{ display: "flex", flexDirection: "column", flex: "1 1 220px", maxHeight: "70vh", overflowY: "auto", paddingLeft: "1rem", paddingTop: "1rem" }}>
       
      </div> */}
        <ImageWithCheck src={catastroImageUrl}/>
    </div>
    return result;
    //return renderToString(<TableKeyValue dataObject={data} replacements={replacements} />)
  }else{
    console.log("No tengo datos pra mostrar el modal", feature)
  }
  return false;
}


export const LAYER_SOLAR_MAP_V2 = new LayerClass(
  "Potencial solar",
  legend,
  getBackground,
  getTooltip,
  getSolarModal, updateLayerStyle,
  getVectorLayerFromSources, clusterStyleFunction, //individualStyleFromCluster,
  BREAKPOINT_ZOOM, handleMapPointerMove
);